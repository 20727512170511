import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "global-goal",
  data() {
    return {
      valid: false,
      isSaving: false,
      globalGoal: {
        id: null,
        title: "",
        description: "",
      },
      globalGoalId: null,
      tabIndex: 0,
      search: "",
      requiredRule: [v => !!v || "Field is required"]
    };
  },
  created() {
    this.globalGoalId = this.$route.params.globalGoalId;
    let title =
      this.globalGoalId != null
        ? "Global goal details"
        : "Insert new global goal";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.globalGoalId != null) {
      ApiService.get(`api/global-goal`, `${this.globalGoalId}`)
        .then(response => {
          this.$log.debug("Global goal: ", response.data);
          this.globalGoal = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    validateAndSave(){
      if (this.$refs.form.validate()){
        this.saveGlobalGoal(this.globalGoal);
      }
    },
    saveGlobalGoal(globalGoal) {
      this.isSaving = true;
      this.loading = true;
      if (globalGoal.id != null) {
        ApiService.put(`api/global-goal`, globalGoal)
          .then(response => {
            this.$log.debug("Global goal updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/global-goal`, globalGoal)
          .then(response => {
            this.$log.debug("Global goal created: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
